import Compressor from 'compressorjs';
import { CookieStore } from '../services/cookieStore';
import { links } from '../components/submission/common';

const { REACT_APP_SITE_URL, REACT_APP_SITE_URL_DEV } = process.env;

export function isIframe() {
  return window.self !== window.top;
}

export const redirectTo = (url: string) => window.location.replace(url);

export const currentUrl = (): string => window.location.href;

export const redirectToNewItem = (itemId: string) => {
  redirectTo(
    `${process.env.REACT_APP_SELLER_DASHBOARD_URL}/scheduling?itemId=${itemId}&survey=true` || '',
  );
};

export const isMobile = () => process.env.REACT_APP_DEVTYPE === 'mobile';
export const isTablet = () => process.env.REACT_APP_DEVTYPE === 'tablet';
export const isDesktop = () => process.env.REACT_APP_DEVTYPE === 'desktop';

export const parametrize = (value: string) => {
  return value
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
};

// this fuction should be called exactly when the session is needed , the cookie need few seconds to be set
export const getSessionData = () => CookieStore.getSessionData();

export const compress = (file: File | Blob): Promise<File | Blob> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(file, {
        quality: 0.8,
        maxHeight: 4096,
        maxWidth: 4096,
        success: resolve,
        error: (error) => {
          console.info(`Error while compressing ${JSON.stringify(error)}`);
          resolve(file);
        },
      }),
  );

export const getItemType = () => {
  const path = window.location.pathname;
  if (path.startsWith('/ring')) return 'ring';
  if (path.startsWith('/earrings')) return 'earrings';
  if (path.startsWith('/bracelet')) return 'bracelet';
  if (path.startsWith('/necklace')) return 'necklace';
  if (path.startsWith('/loose_diamond')) return 'loose diamond';
  if (path.startsWith('/watch')) return 'watch';
  return '';
};

export const directToItemTypePage = () => {
  window.location.href = links.submissionFlowRepositioning;
};

export const driectToHomePage = () =>
  redirectTo(REACT_APP_SITE_URL_DEV || REACT_APP_SITE_URL || '');

export const isPhotoMandatory = ({ forcePhoto, carat }: { forcePhoto: boolean; carat: number }) => {
  const itemType = getItemType();
  return itemType === 'watch' || forcePhoto || (itemType === 'ring' && carat === 0);
};

export function fileToDataUrl(
  file: any,
): Promise<{ src: string; id: string; url: string; type: string }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      resolve({
        src: event.target.result,
        id: file.lastModified,
        url: event.target.result,
        type: 'img',
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}

export const dataUrlToFile = (dataUrl: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', dataUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (this.status === 200) {
        const blob = this.response;

        let fileType = blob.type.split('/')[1];
        if (fileType === 'jpeg') fileType = 'jpg'; // jpeg -> jpg

        const file = new File([blob], `image.${fileType}`, { type: blob.type });
        resolve(file);
      } else {
        console.error('Error while converting dataUrl to file');
      }
    };

    xhr.onerror = function () {
      console.error('Error while converting dataUrl to file');
    };

    xhr.send();
  });
};

export const toCamelCase = (str: string) => {
  return str
    .split(' ')
    .map((word: string, idx: number) =>
      idx === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join('');
};

export const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
